<template>
  <div>
    <div id="chart_main"></div>
  </div>
</template>

<script>
import { cardStat } from "@/http/chart";
let echarts = require("echarts");

export default {
  data() {
    let self = this
    return {
      myChart: null,
      detail: {},
      option: {
        title: {
          text: "基础雷达图",
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          left: "24",
          right: "24",
          bottom: "24",
          top: "60",
          containLabel: true,
        },
        radar: {
          // shape: 'circle',
          name: {
            formatter(value) {
              return `${value}【${self.detail[value] || 0}】`;
            },
          },
          indicator: [],
        },
        series: [
          {
            name: "b",
            type: "radar",
            data: [
              {
                value: [],
                name: "a",
                itemStyle: {
                  color: "#28C6D4",
                },
                areaStyle: {
                  color: "rgba(40, 198, 212, .4)",
                },
              },
            ],
          },
        ],
      },
    };
  },
  components: {},
  created() {
    console.log("33");
  },
  computed: {},
  mounted() {
    this.myChart = echarts.init(document.getElementById("chart_main"));
    console.log("222");
    this.cardStat();
  },
  methods: {
    cardStat() {
      let router = this.$route.params;
      let params = {
        studentCode: router.studentCode,
        schoolId: router.schoolId,
        sn: router.sn,
      };
      cardStat(params).then((res) => {
        let indicator = [],
          value = [],
          allCount = 0;
        res.data.forEach((item) => {
          indicator.push({ name: item.cardTypeName, max: 20 });
          value.push(item.count);
          allCount += item.count;
          this.detail[item.cardTypeName] = item.count;
        });
        this.$emit("update:allCount", allCount);
        if (indicator.length == 0) {
          return;
        }
        console.log("indicator", indicator, value);
        this.option.radar.indicator = indicator;
        this.option.series[0].data[0].value = value;
        this.myChart.setOption(this.option);
      });
    },
  },
};
</script>

<style lang="scss">
#chart_main {
  width: 100%;
  height: 300px;
}
</style>