import request from './request'

/**
 * 获取echarts 图表显示
 */

export const cardStat = (data) => {
  return request({
    url: `/awardcard/api/card/stat`,
    method: 'post',
    data
  })
}
